<template>
  <div class="container"></div>
</template>

<script>
export default {
  name: 'Objects',
  mounted() {
    this.$router.push('/objects/apartments')
  }
}
</script>
